<template>
  <CSidebar
      :minimize="minimize"
      unfoldable
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
<!--    <CSidebarBrand class="d-md-down-none" to="/">-->

<!--      <img src="@/assets/logo.png" height="40"/>-->
      <!--      <img src="@/assets/logo-mfu-banner.png" height="48"/>-->
      <!--            <CIcon-->
      <!--              class="c-sidebar-brand-full"-->
      <!--              name="logo"-->
      <!--              size="custom-size"-->
      <!--              :height="35"-->
      <!--              viewBox="0 0 642 134"-->
      <!--            />-->
      <!--            <CIcon-->
      <!--              class="c-sidebar-brand-minimized"-->
      <!--              name="logo"-->
      <!--              size="custom-size"-->
      <!--              :height="35"-->
      <!--              viewBox="0 0 110 134"-->
      <!--            />-->
<!--    </CSidebarBrand>-->

    <!--    <div class="avatar-site text-center mt-4">-->
    <!--      <img src="img/avatars/6.jpg" class="c-avatar-img " alt="" style="height: 120px; width: 120px;">-->
    <!--    </div>-->
    <!--    <div class="text-center mt-3">-->
    <!--      <span>Mr. DIY</span>-->
    <!--    </div>-->
    <div class="text-center" v-if="this.$store.getters['gateway/objProfile']._id">
      <label for="input-vj" class="c-avatar img-avatar mt-4 border-white shadow"
             style="height: 140px; width: 140px; margin: auto;">
        <div class="avatar-site2">
          <img :src="imageProfile"
               @error="setAltImg"
               alt="" width="140px" height="140px" style="border-radius: 70px; ">
        </div>
      </label>
      <input id="input-vj" type="file" accept="image/*" @change=uploadImage style="display: none;"/>
    </div>

    <CRow class="mt-4" v-if="this.$store.getters['gateway/objProfile']._id">
      <CCol>
        <div class="pl-4 pr-4">
          <CInput
              v-model="displayName"
              placeholder="Username"
              autocomplete="username email"
          >
            <template #prepend-content><CIcon name="cil-user"/></template>
          </CInput>
        </div>
      </CCol>
    </CRow>

    <CRenderFunction flat :contentToRender="(imageProfile)?nav:navs"/>
<!--    <CSidebarMinimizer-->
<!--        class="c-d-md-down-none"-->
<!--        @click.native="$store.commit('toggle', 'sidebarMinimize')"-->
<!--    />-->
  </CSidebar>
</template>

<script>

import SidebarItems from './SidebarItems'
import {mapGetters} from "vuex";
import Metamask from "@/project/Banks";

export default {
  name: 'TheSidebar',
  extends: SidebarItems,

  data: function () {
    return {
      // nav:this.onNav(),

      navs: [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavTitle',
              _children: ['MAIN MENU']
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Market',
              to: '/markets',
              icon: 'cil-home'
            },

            {
              _name: 'CSidebarNavItem',
              icon: 'cil-library-add',
              name: 'My Collection',
              to: '/collection/list'
            },
            {
              _name: 'CSidebarNavItem',
              icon: 'cil-wallpaper',
              name: 'My NFT Asset',
              to: '/assets'
            }
          ]
        }],
      nav: [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavTitle',
              _children: ['MAIN MENU']
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Market',
              to: '/markets',
              icon: 'cil-home'
            },
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Swap Token',
            //   to: '/swap',
            //   icon: 'cil-swap-horizontal'
            // },
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Mint NFT',
            //   to: '/nft',
            //   icon: 'cil-layers'
            // },
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Gallery',
            //   to: '/gallery',
            //   icon: 'cil-wallpaper'
            // },

            // {
            //   _name: 'CSidebarNavItem',
            //   icon: 'cil-layers',
            //   name: 'Create Collection',
            //   to: '/collection/create'
            // },

            {
              _name: 'CSidebarNavItem',
              icon: 'cil-library-add',
              name: 'My Collection',
              to: '/collection/list'
            },
            {
              _name: 'CSidebarNavItem',
              icon: 'cil-wallpaper',
              name: 'My NFT Asset',
              to: '/assets'
            }
          ]
        }],
      imageProfile: '',
      displayName:this.$store.getters['gateway/objProfile'].displayName,
      state:false,
    }
  },


  created() {
    // this.onConnectMataMaskAddress();
  },

  computed:{
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    ...mapGetters({
      profile:'gateway/objProfile'
    })
  },

  watch: {

    profile(value){
      this.imageProfile = (value.userinfo.image_profile == null)?"":value.userinfo.image_profile;
      this.displayName = value.displayName;
      this.decription = value.bio.about;

      console.log(value)

      this.$store.dispatch('metamask/onConnect')
      // var obj = {};
      // obj.metamask = value.metamask;
      // this.$store.dispatch('gateway/onProfile',obj)

      // this.onGetProfile();
    },

    displayName(value){
      // if(this.$store.getters['metamask/onGetMetaMaskAddress'] != null){
      //   let data = new FormData();
      //   data.append("metamask", this.$store.getters['metamask/onGetMetaMaskAddress']);
      //   data.append('displayName', this.displayName);
      //   this.$store.dispatch('gateway/onUpdateProfile',data)
      // }

    }
  },

  methods: {

    setAltImg(event) {
      event.target.src = "img/token/nextart.png"
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.imageProfile = e.target.result;
        // this.onUploadImage(image)

        let data = new FormData();
        data.append("metamask", this.$store.getters['metamask/onGetMetaMaskAddress']);
        data.append('image', image, image.fileName);
        this.$store.dispatch('gateway/onUpdateProfile',data)
      };
    },

    onUpdaDateProfile(){
      let data = new FormData();
      data.append("metamask", this.collections._id);
      data.append("displayName", this.collections.onwer._id);
      data.append("decription", this.collections.onwer._id);
      data.append('image', this.image, this.image.fileName);
    },

    //
    onConnectMataMaskAddress(){
      this.$store.dispatch('metamask/onMetaMaskAddress')
    },

    // onGetProfile(){
    //   var body = {};
    //   body.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
    //   this.$store.dispatch('gateway/onProfile',body)
    // }
  }
}
</script>
<style>

.image-upload > input {
  display: none;
}

.image-upload img {
  width: 80px;
  cursor: pointer;
}

.inp {
  box-sizing: border-box;
  background: transparent;
  border: none;
  text-align: center;
  color: white;
}

.avatar-site {
  width: 100px;
  height: 100px;
  margin: auto;
}
</style>
