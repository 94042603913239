<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheSidebar/>
    <TheAside/>
    <CWrapper>
      <CenterLoading/>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
        <CenterDialogs/>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheAside from './TheAside'
import {mapGetters} from "vuex";
import CenterDialogs from "@/containers/CenterDialogs";
import CenterLoading from "@/containers/CenterLoading";

export default {
  name: 'TheContainer',
  components: {
    CenterLoading,
    CenterDialogs,
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside
  },

  computed:{
    ...mapGetters({
      objJwt:'auth/objJwt',
    })
  },

  watch: {
    objJwt:function (data) {
      console.log(data)

    }
  },

  created() {
    // this.toggleTimer();
  },

  onIdle() {
    console.log("onIdle")
    this.active = false;
  },
  onActive() {
    console.log("onActive")
    this.active = true;
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
